.submit-egg form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    label {
        text-align: center;
    }
    input {
        border: 1px solid grey;
        border-radius: 5px;
        outline: none;
        color: black;
        background: darken(white, 10%);
        height: 30px;
        width: 80px;
        padding: 0 20px;
        text-align: center;
    }
    .button {
        padding: 10px 20px;
        border: 1px solid black;
        cursor: pointer;
        border-radius: 0.5em;
        background-image: linear-gradient(to bottom, #ffeb19 0%, #ffeb19 50%, #ffcd00 100%);
        background-position: 0px -50px;
        background-size: 1px 100px;
    }
}
.new-points-container {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .new-points {
        padding: 30px;
        min-width: 150px;
        font-size: 60px;
        font-weight: 1000;
        text-align: center;
        color: white;
        background-color: rgba(233, 18, 18, 0.5);
        border-radius: 30%;
        animation: blinker 1.2s linear infinite;
    }
}

@keyframes blinker {
    10% {
        color: yellow;
    }
    20% {
        color: orange;
    }
    30% {
        color: red;
    }
    40% {
        color: purple;
    }
    50% {
        color: blue;
    }
    60% {
        color: green;
    }
}
