.egg-log {
    background-color: black;
    color: white;
    font-size: 12pt;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    height: 10vh;

    .green-text {
        color: green;
    }

    p {
        animation: xAxis 15s infinite linear;
        width: 100vw;
        height: 20px;
        display: block;
        margin: 0;
    }
}

@keyframes xAxis {
    0% {
        transform: translate3d(-500vw, 0, 0);
        visibility: visible;
    }
    100% {
        transform: translate3d(300vw, 0, 0);
    }
}
