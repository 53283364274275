.egg-hunt-page {
    .player-info {
        width: 100%;
        grid-area: header;
        min-height: 5vh;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: white;
        gap: 10px;
        .player-color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--player-color);
        }
        .number-of-eggs {
            margin-left: auto;
        }
    }
    .page {
        .page-header {
            height: 5vh;
        }
        .page-content {
            height: 75vh;
        }
        .page-footer {
            height: 5vh;
        }
    }
}
