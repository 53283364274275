.egg-hunt-thank-you-page {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 500px;
    overflow-y: scroll;
    h1 {
        font-size: 20px;
        width: 100%;
        letter-spacing: 15px;
        display: inline-block;
        padding: 5px;
        background: #2d3436;
        color: white;
        text-align: center;
    }
    p {
        font-size: 20px;
        width: 100%;
        letter-spacing: 5px;
        display: inline-block;
        padding: 10px;
        text-align: center;
    }
    img {
        max-width: 80%;
        height: auto;
    }

    &.page {
        .page-header {
            display: none;
        }
        .page-content {
        }
        .page-footer {
            display: none;
        }
    }
}
