.page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main"
        "footer";
    height: 100vh;
    height: calc(var(--vh, 1vh) * 90);
    .page-header {
        grid-area: header;
        max-width: 100%;
        overflow: hidden;
        min-height: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px 5px 5px;
        justify-content: center;
    }
    .page-content {
        grid-area: main;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 75vh;

        .page-column {
            max-width: 50vw;
            overflow: scroll;
        }
    }
    .page-footer {
        grid-area: footer;
        max-width: 100%;
        overflow: hidden;
        min-height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
