.egg-map-admin {
    position: relative;
    width: 180px;
    display: flex;
    flex-direction: column;
    .marker {
        position: absolute;
        top: calc(var(--marker-y) - 4px);
        left: calc(var(--marker-x) - 4px);
        width: 8px;
        height: 8px;
        background-color: gold;
        border-radius: 80% 15% 55% 50% / 55% 15% 80% 50%;
        border: 1px solid black;
        transform: rotate(-45deg);
    }
}
