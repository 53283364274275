.egg-hunt-admin-map {
    &.page {
        .page-header {
            height: 5vh;
        }
        .page-content {
            height: 75vh;
        }
        .page-footer {
            height: 10vh;
        }
    }
}
