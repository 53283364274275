.app {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  flex: 1;
  // overflow: hidden;
}
