.register-page {
    h1 {
        font-size: 30px;
        width: 100%;
        letter-spacing: 15px;
        display: inline-block;
        padding: 5px;
        background: #2d3436;
        color: white;
        text-align: center;
    }
    p {
        font-size: 20px;
        width: 100%;
        letter-spacing: 5px;
        display: inline-block;
        padding: 5px;
        text-align: center;
    }
    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        input {
            border: 1px solid grey;
            border-radius: 5px;
            outline: none;
            width: 100%;
            color: black;
            background: darken(white, 10%);
            padding: 1em;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        .button {
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            border: 1px solid black;
            cursor: pointer;
            border-radius: 0.5em;
            background-image: linear-gradient(to bottom, #ffeb19 0%, #ffeb19 50%, #ffcd00 100%);
            background-position: 0px -50px;
            background-size: 1px 100px;
            transition: all 0.3s ease;
        }
    }
}
