.admin-dashboard {
    p {
        font-size: 20px;
        width: calc(100% - 10px);
        letter-spacing: 5px;
        display: inline-block;
        padding: 5px;
        text-align: center;
    }
    .button-bar,
    .navigation-buttons,
    .points-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
        span {
            padding: 5px 10px;
            min-width: 60px;
            text-align: center;
            background-color: white;
            border-width: 2px;
            border-style: solid;
            border-color: blue;
            border-radius: 10px;
            color: blue;
            cursor: pointer;
        }
    }
    .points-bar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 2px;
        span {
            padding: 5px 5px;
            &.selected {
                border-color: white;
                color: white;
                background-color: blue;
            }
        }
    }
}
