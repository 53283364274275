html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-width: 100vw;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("~/public/background.jpg") fixed;
  box-shadow: inset 0 0 0 100vh rgba(255, 255, 255, 0.9);
  background-position: center;
  background-size: 100%;
  background-repeat: repeat;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
