.register-eggs {
    .button-bar,
    .navigation-buttons,
    .points-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 20px;
        span {
            padding: 10px 20px;
            min-width: 60px;
            text-align: center;
            background-color: white;
            border-width: 2px;
            border-style: solid;
            border-color: blue;
            border-radius: 10px;
            color: blue;
            cursor: pointer;
        }
    }
    .points-bar {
        flex-wrap: wrap;
        gap: 2px;
        span {
            padding: 5px 10px;
            &.selected {
                border-color: white;
                color: white;
                background-color: blue;
            }
        }
    }
    &.page {
        .page-header {
            height: 5vh;
        }
        .page-content {
            height: 75vh;
        }
        .page-footer {
            height: 10vh;
        }
    }
}
