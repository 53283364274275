.egg-ranking {
    margin-top: 5px;
    width: calc(100% - 6px);
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: scroll;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 3px;
        max-width: calc(100% - 6px);
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        li {
            display: grid;
            grid-template-columns: 12% 6% 45% 10% 22%;
            gap: 5px;
            padding: 6px 12px;
            width: calc(100% - 24px);
            border-radius: 10px;
            background-color: #f3f1f8;
            box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1);
            font-size: 14px;
            span {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 5px;
                &.player-color {
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 3px;
                    height: 13px;
                    border-radius: 50%;
                    background-color: var(--player-color);
                }
            }
        }
    }
}
