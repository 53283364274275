.egg-map {
    position: relative;
    width: 180px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .marker {
        position: absolute;
        top: calc(var(--marker-y) - (var(--marker-size, 8px) / 2));
        left: calc(var(--marker-x) - (var(--marker-size, 8px) / 2));
        width: var(--marker-size, 8px);
        height: var(--marker-size, 8px);
        background-color: var(--marker-color);
        border-radius: 80% 15% 55% 50% / 55% 15% 80% 50%;
        border: 1px solid black;
        transform: rotate(-45deg);
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            text-align: center;
            color: white;
            transform: rotate(45deg);
            h3 {
                font-size: 20pt;
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }
}
